.spin__outlined {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  font-size: 40px;
}
.product__overview {
  padding: 0.5rem 0 0 1.1rem;
  border-bottom: 1px solid #ededed;
}
.main_content {
  flex: 1 1;
  background-color: $solid__border__color;
  border: 1px solid $solid__border__color;
  border-radius: 10px;
  padding: 25px;
}
.navigationButtons {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 40px;
}

.navigationButtons a {
  border-radius: 5px;
  display: inline-flex;
  min-width: 40px;
  height: 36px;
  margin: 0 6px;
  padding: 4px 6px 4px 15px;
  color: $black__color !important;
  cursor: pointer;
  text-decoration: none;
  font-family: $text__headline__regular !important;
}

.navigationButtons a:hover {
  color: $black__color;
}

.navigationActive a {
  color: $white__color !important;
  background-color: $darkblue__color;
}

.navigationDisabled a {
  color: $gray__color !important;
  background-color: none;
  border-color: none !important;
}
.navigationDisabled a:hover {
  cursor: not-allowed;
  color: $gray__color;
}
.icon__size {
  width: 28px;
}
.main__header {
  h2 {
    font-family: $text__headline__bold;
    color: $darkblue__color;
    font-size: 1.5rem;
  }
}
.sub__header {
  font-family: $text__headline__regular;
  p {
    color: $blue__shade__color;
    font-size: 18px;
  }
  span {
    color: $black__color !important;
  }
}
.new__prd {
  background-color: $blue__shade__color !important;
  font-family: $text__headline__regular !important;
  float: right;
  border-color: $blue__shade__color !important;
  &:hover {
    background-color: $blue__shade__color !important;
  }
}
.product__speci {
  display: flex;
  gap: 10px;
  img {
    width: 1.8rem;
  }
  span {
    font-family: $text__headline__regular;
    color: $gray__color;
  }
}
.spacing__up {
  padding-bottom: 1.5rem !important ;
}
.product__name {
  color: $darkblue__color !important;
  font-family: $text__headline__bold !important;
  font-size: 18px !important;
}
.prod__desc {
  font-family: $text__headline__regular;
  font-size: 16px !important;
  color: $black__color !important;
  margin-top: 0.7rem !important;
}
.overlay-tooltip {
  margin-top: 1rem;
}
.margin__spacing {
  margin-top: 1.5rem !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.page__info {
  font-family: $text__headline__regular;
  color: $black__color;
  span {
    font-family: $text__headline__bold;
    color: $black__color;
    font-size: 14px;
  }
}
ul.overlay-tooltip {
  padding-left: 0;
  li {
    margin-right: 0.5rem;
    display: inline-flex;
  }
}
.pill__inner {
  border-radius: 15px !important;
  white-space: nowrap;
  background: rgba(90, 90, 90, 0.5);
  border: none;
  padding: 0.1rem 1.5rem 0.2rem 1.5rem;
  font-family: $text__headline__regular;
  color: $black__color;
}
.display__btn {
  display: inline-flex;
  justify-content: end;
  gap: 12px;
  button {
    border-radius: 0.2rem;
    width: 45px;
    height: 45px;
    &:hover {
      background-color: $blue__light__color !important;
    }
  }
  .active-button {
    background-color: $blue__light__color;
    border: 1px solid $blue__shade__color !important;
  }

  .inactive-button {
    background-color: $light__gray__color;
    border: 1px solid $mid__gray !important;
  }
  img {
    width: 1.2rem;
  }
}
.header__container {
  padding-top: 5rem !important;
  padding-bottom: 1rem !important;
}
.container__width__overall {
  width: 95% !important;
}

input[type="search"] {
  background: $white__color;
  margin: 0;
  padding: 8px !important;
  font-size: 14px;
  color: $black__color;
  border: 1px solid $mid__gray !important;
  border-radius: 5px !important;
  width: 100%;
  font-family: $text__headline__regular;
}
.time__st {
  font-family: $text__headline__bold !important;
  font-size: 16px;
  color: $black__color !important;
}
.time__val {
  font-family: $text__headline__regular !important;
  color: $black__color !important;
}

.date-filter {
  margin-top: 1rem;
  button {
    background-color: $white__color;
    border-color: $darkblue__color;
    &:hover {
      background-color: $white__color !important;
      border-color: $darkblue__color !important;
    }
  }

  span {
    font-family: $text__headline__regular !important;
    font-size: 14px;
    color: $black__color;
  }
}
.calendar {
  filter: brightness(0, 0, 0, white);
}
.crumb__width {
  max-width: 95% !important;
}
.viewall__breadcrumb__main {
  padding-top: 0rem !important;
  a {
    font-family: $text__headline__regular !important;
    color: $blue__color;
    font-size: 18px;
  }
  a:hover {
    color: $blue__color;
  }
}
.cata__breadcrumb__val {
  font-family: $text__headline__regular !important;
  color: $gray__color !important;
  font-size: 18px;
  .tip__name {
    font-family: $text__headline__regular !important;
    font-size: 14px !important;
  }
}

.date__apply {
  background-color: $blue__color !important;
  border-color: $blue__color !important;
  font-family: $text__headline__regular !important;
  color: $white__color;
  &:hover {
    background-color: $blue__color !important;
  }
}
