html{
  font-family: MaerskText-Regular; 
}

body {
  font-family: MaerskText-Regular; 
  font-size: 16px !important;
  line-height: 26px;
  letter-spacing: 0;
  color: #141414;
}
.text-default{
  color: #141414;
}
.w-90{
  width: 90% !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: MaerskHeadline-Light;
}
.prod-text-header{
  font-family: MaerskHeadline-regular;
}
p{
  color: #141414;
  font-size: 16px !important;
}
ul{
margin:0px;
}
a {
  color: transparent;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none;
  color: #000;
}
a:focus {
  outline: 0;
}
ul{
  padding:0;
}
.lh-28{
  line-height: 28px;
}
.lh-35{
  line-height: 35px;
}
.w-85{
  width: 85%;
}
.maersk-blue-bg{
  background-color: #42B0D5 !important;
  border-color: #42B0D5 !important;
}
.maersk-dark-blue-bg{
  background-color: #00243D !important;
}
.maersk-mid-blue-text{
  color: #0073AB;
}
.lead {
  font-size: 1.15rem !important;
  line-height: 28px;
}
.navbar-brand{
  background: #00243D !important;
}
.sb-topnav .navbar-brand img{
  width: 140px;
  z-index: 1 !important;
  position: relative;
}
#sidebarToggle img{
  width: 50px;
}
.dotted-left-top{
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  z-index: -1;
}
.pseudo_border {
  position:relative;
  display:inline-block;
}
.pseudo_border:after {
  content:'';
  position:absolute;
  left:0; right:0;
  top:100%;
  margin:15px auto;
  width:25%;
  height:5px;
  background:#42B0D5;
}
.section-1{
  /* background: url(../img/section-1-BG.png) no-repeat top;
  background-size: cover;
  position: relative;
  top: -60px; */
   /* background: url(../img/section-1-BG.png) no-repeat top;
  background-size: cover; */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ebf1f6+0,d5ebfb+100 */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d5ebfb+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #ffffff 0%, #d5ebfb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #ffffff 0%,#d5ebfb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(362deg,  #ffffff 0%,#d5ebfb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d5ebfb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
.section-3{
  background: rgba(247,247,247,0.5);
}
.section-4{
  background: rgba(181,224,245,0.3);
}
.container-fluid.dotted-1{
  background: url(../img/dotted-1.png) left top no-repeat;
  background-size: 25%;
  background-position: top left;
}
.section-5{
  background: url(../img/dotted-2.png) center center no-repeat;
  background-size: 90%;
  background-position: top right;
}
.section-6{
  background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
    url('../img/maersk-bg.jpg');
    background-size: cover;
    background-position: center;
}
.gradient-text-1{
  background: #00243D;
  background: linear-gradient(to right, #00243D 0%, #FA6A55 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text-2{
  background: #00243D;
  background: linear-gradient(to right, #0CA0CE 0%, #005487 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.more-details a{
  color: #42B0D5;
  font-weight: bold;
  text-decoration: none;
}
.more-details a:hover{
  color: #0073AB;
  font-weight: bold;
  text-decoration: none;
}
/* .py-10{
  padding-top: 10rem;
  padding-bottom: 10rem;
} */
.px-6 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}
.display-7{
  font-size: 1.25rem;
}
.sb-sidenav-dark, .sb-sidenav-toggled .navbar-dark{
  background: #00243D;
}
.vcenter-item{
  min-height: 10em;
  display: table-cell;
  vertical-align: middle
}
/* Typewriter effect 1 */
/* @keyframes typing {
  0.0000%, 27.3488% { content: ""; }
  1.1395%, 26.2093% { content: "d"; }
  2.2791%, 25.0698% { content: "da"; }
  3.4186%, 23.9302% { content: "dat"; }
  4.5581%, 22.7907% { content: "data"; }
  5.6977%, 21.6512% { content: "datal"; }
  6.8372%, 20.5116% { content: "datala"; }
  7.9767%, 19.3721% { content: "datalab"; }
 
  30.7674%, 51.2791% { content: ""; }
  31.9070%, 50.1395% { content: "d"; }
  33.0465%, 49.0000% { content: "da"; }
  34.1860%, 47.8605% { content: "dat"; }
  35.3256%, 46.7209% { content: "data"; }
  36.4651%, 45.5814% { content: "data "; }
  37.6047%, 44.4419% { content: "data c"; }
  38.7443%, 43.3024% { content: "data cr"; }
  39.8838%, 42.1629% { content: "data cre"; }
  41.0234%, 40.0234% { content: "data crea"; }
  42.1630%, 39.1629% { content: "data creat"; }
  43.3026%, 38.3024% { content: "data creati"; }
  44.4422%, 37.4419% { content: "data creativ"; }
  45.5518%, 37.4419% { content: "data creative"; }

  54.6977%, 75.2093% { content: ""; }
  55.8372%, 74.0698% { content: "d"; }
  56.9767%, 72.9302% { content: "da"; }
  58.1163%, 71.7907% { content: "dat"; }
  59.2558%, 70.6512% { content: "data"; }
  60.3953%, 69.5116% { content: "datal"; }
  61.5349%, 68.3721% { content: "datala"; }
  62.5349%, 67.3721% { content: "datalab"; }

  78.6279%, 96.8605% { content: ""; }
  79.7674%, 95.7209% { content: "d"; }
  80.9070%, 94.5814% { content: "da"; }
  82.0465%, 93.4419% { content: "dat"; }
  83.1860%, 92.3023% { content: "data"; }
  84.3256%, 91.1628% { content: "datal"; }
  85.3256%, 90.1628% { content: "datala"; }
  86.3256%, 89.1628% { content: "datalab"; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 13.5s infinite;
}

.typewriter::after {
  content: "";
  border-right: 1px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}


@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }
  
  @keyframes sequencePopup {
    0%, 100% { content: "datalab"; }
    25% { content: "Data Creativity Space"; }
    50% { content: "Center of Digital Social research"; }
    75% { content: "human"; }
  }

  .typewriter::before {
    content: "developer";
    animation: sequencePopup 12s linear infinite;
  }
} */
.Typewriter{
  display: inline !important;
}

.tab-content {
  color : white;
  background-color: white;
  padding : 5px 15px;
}

.tab-pane li{
  line-height: 28px;
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  padding-left: 40px;
}
.tab-pane li:before{
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 35px;
  height: 35px;
  background: url(../img/list-tick.png) no-repeat;
}
ul.slider-list li {
  line-height: 28px;
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  padding-left: 40px;
}
ul.slider-list li:before{
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 35px;
  height: 35px;
  background: url(../img/list-tick.png) no-repeat;
}
/* remove border radius for the tab */
.nav-pills > li > a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

.nav-pills > li > a {
  border-radius: 4px 4px 0 0 ;
}
.tab-content {
  color : white;
  background-color: #428bca;
  padding : 5px 15px;
}
.img-thumbnail-1{
  width: 60px;
  padding: 10px;
  display: block;
  text-align: center;
}
.nav-tabs {
  border-bottom: 0px !important;
}
.nav-tabs button{
  width: 23% !important;
  margin: 0 auto !important;
}
.nav-tabs .nav-link{
  color: #141414;
  padding: 15px;
}
.nav-tabs .nav-link.active{
  border-color: transparent !important;
}

#nav-home-tab{
  border-image-slice: 1;
  border-width: 6px;
  border-image-source: linear-gradient(to left, #FA6A55, #878787);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin: 15px;
  /* margin-left: 0; */
  width: 22.5%;
  padding: 0;
}
.nav-link.active .selected{
  height: 20px;
  width: 20px;
  background: url(../img/minus.png) no-repeat;
  position: relative;
  margin: 0 auto;
  top: 28px;
  background-size: 100%;
}
.selected{
  height: 20px;
  width: 20px;
  background: url(../img/plus.png) no-repeat;
  position: relative;
  margin: 0 auto;
  top: 28px;
  background-size: 100%;
}
.dropdown-toggle::after{
  color: #00243D;
}
.user-icon {
  /* height: 50px;
  width: 50px; */
  padding: 0 !important;
  border-radius: 50%;
  color: #fff;
  border:none !important;
  background: transparent !important;
}
.user-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #CFCFCF !important;
  background: transparent;
  cursor: pointer;
}

/* #nav-home-tab::after{
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background: #005487;
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 7px;
}  */

.tab-panel-variation{
  margin-top: 3%;
  margin-left: 12%;
}

#nav-profile-tab{
  border-image-slice: 1;
  border-width: 6px;
  border-image-source: linear-gradient(to left, #005487, #42B0D5);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 0px;
  margin: 15px;
  width: 22.5%;
}
/* #nav-profile-tab::after{
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background: #005487;
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 7px;
} */
#nav-contact-tab{
  border-image-slice: 1;
  border-width: 6px;
  border-image-source: linear-gradient(to left, #009687, #A3BF00);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 0px;
  margin: 15px;
  width: 22.5%;
}
/* #nav-contact-tab::after{
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background: #005487;
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 7px;
} */
#nav-access-tab{
  border-image-slice: 1;
  border-width: 6px;
  border-image-source: linear-gradient(to left, #B80012, #FF9100);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin: 15px;
  padding: 0;
  width: 22.5%;
}
/* #nav-access-tab::after{
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background: #005487;
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 7px;
} */
.carousel-caption{
  text-align: left;
}
.carousel-item {
  /* height: 65vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  height: 100%;
  /* min-height: 450px; */
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px !important; 
  height: 10px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  /* background-color: #000;
  background-clip: padding-box;
  border: 0;
  /* border-top: 10px solid transparent; 
  /* border-bottom: 10px solid transparent; 
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border-radius: 50px; */
  background-color: #878787 !important;
  border-radius: 50% !important;
}
.carousel-indicators .active{
  background: #42B0D5 !important;
  width: 10px !important; 
  height: 10px !important;
}
.image-gallery-slides{
  white-space: normal !important;
}
/* .image-gallery-thumbnails-container button:first-child span:after {
  content: "Flexible Deployment";
  position: absolute;
  top: 75%;
  text-align: center;
  display: block;
  width: 100% !important;
}
.image-gallery-thumbnails-container button:nth-child(2) span:after {
  content: "Self Service And Customization";
  position: absolute;
  top: 75%;
  text-align: center;
  display: block;
  width: 100% !important;
} */
.contact-btn{
  background:#42B0D5 !important;
  border-color: #42B0D5 !important;
  padding: 10px 40px;
  outline: 0;
  border: none;
  color:#fff;
  border-radius:5px;
}
.heart-img{
  width: 15px;
}
.footer{
  background: #003D6D;
}
/* .footer-iframe{
  position: static;
  margin-top: 115vh;
  margin-left: 225px;
}
.footer-iframe-page{
  position: static;
  margin-top: 77vh;
  margin-left: 225px;
} */
/* .footer-table-page{
  position: static;
  margin-top: 25vh;
  margin-left: 225px;
} */
.image-gallery-icon{
  filter: none !important;
  color: #141414 !important;
}
.rounded-border5{
  border: 10px solid #B5E0F5;
  border-radius: 10px;
}

/* .tab-card-landing{
  margin-left: 75px !important;
} */

@media only screen and (max-width: 1600px) {
  #nav-home-tab, #nav-profile-tab, #nav-contact-tab, #nav-access-tab{
    width: 22%;
  }
}
@media only screen and (max-width: 1440px) {
  #nav-home-tab, #nav-profile-tab, #nav-contact-tab, #nav-access-tab{
    width: 20%;
  }
    #mobile{
      padding: 0px !important;
    }
  }
@media only screen and (max-width: 1200px) {
  .px-6{
    padding: 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .display-7{
    font-size: 1rem !important;
  }
  #nav-home-tab, #nav-profile-tab, #nav-contact-tab, #nav-access-tab{
    margin: 0 !important;
    width: 25% !important;
  }
  #nav-home-tab img, #nav-profile-tab img, #nav-contact-tab img, #nav-access-tab img{
   width: 50%;
  }
  #nav-profile-tab, #nav-contact-tab, #nav-access-tab{
    border-left: 0 !important;
  }
  .w-75.full-width{
    width: 100% !important;
  }
  .text-sm-left{
    text-align: left !important;
  }
  .text-sm-right{
    text-align: right !important;
  }
  .text-sm-center{
    text-align: center !important;
  }
  .w-75.d-md-100{
    width: 100% !important;
  }
}
.text-link-decor{
  text-decoration: none;
}
.maestro-logo{
  width: 7rem;
}
.thumbnail-carousel{
margin-top: 40px;
}
