.node {
  cursor: pointer;
}
.node rect {
  stroke: gray;
  stroke-width: 1.5px;
}
.node text {
  font: 13px;
}
.link,
.mpLink {
  fill: none;
  stroke: rgb(14, 2, 2);
  stroke-width: 1px;
}
.level-0 {
  opacity: 0;
}

.my-tooltip {
  position: absolute;
  text-align: left;
  padding: 8px;
  font: 13px sans-serif;
  border-radius:15px;
  background: #00b6ff;
  border: solid 1px #79bae4;
  pointer-events: none;
  word-wrap: break-word;
  color: black;
}
.select-lineage{
  margin-left: 50vh;
  margin-top: 12vh;
}

