//NAVBAR//
.main__container {
  height: 82vh;
  width: 100%;
}
#mainnav {
  position: absolute;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: -4rem;
  right: 11rem;
}

#mainnav li {
  list-style: none;
  position: relative;
  padding-right: 25px;
  cursor: pointer;
}
#mainnav li a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  font-family: $text__headline__regular;
  color: $black__color;
}
// #mobile {
//   display: block;
//   cursor: pointer;
//   background-color: #28ace2;
//   border-radius: 50%;
//   width: 45px;
//   height: 45px;
//   padding-top: 0.25rem;
//   // padding: 2.4rem 0 0 102rem;
//   text-align: center;
// }
// #mobile svg{
//   display: block;
//   cursor: pointer;
//   background-color: #28ace2;
//   border-radius: 50%;
  
//   // padding: 2.4rem 0 0 102rem;
//   text-align: center;
//   // text-align: center;
//   padding: 5px;
// }
#signinnav {
  position: absolute;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: -6rem;
  right: 2rem;
}
#signinnav li {
  list-style: none;
  position: relative;
  padding-right: 25px;
  cursor: pointer;
}
#signinnav li a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  font-family: $text__headline__regular;
  color: $black__color;
}
.signin {
  position: relative;
  top: 2em;
  right: 7em;
  width: 9vw;
  border-color: #28ace2 !important;
  background: $blue__shade__color !important;
}

#wrapper {
  background: url("../../../images/LP-LoggedIn.gif");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.datalab-small-des {
  font-weight: 400;
  font-size: 45px;
  color: $black__color;
}
.datalab-name {
  font-weight: bold;
  font-size: 50px !important;
  color: $blue__shade__color;
}

.bars {
  font-size: 16px;
  // top: 17px;
  cursor: pointer;
  width: 6rem;
  height: 70px;
  position: absolute;
  // left: 15px;
  background: #00243D; 
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #545454;
}

.open__bars {
  font-size: 16px;
  cursor: pointer;
  width: 225px;
  height: 70px;
  position: absolute;
  background: #00243D;
  color: #fff;
  border-bottom: 1px solid #545454;
}

.open__bars svg {
  position: relative;
  left: 15px;
}

.datalab-logo {
  margin-left: 1rem;
  margin-top: 1rem;
  height: 3rem;
  margin-bottom: 1rem;
}

.datalab-logo-nav-open {
  height: 2.5rem;
  // margin: 1rem;
}

.inputWithIcon,
.log-out-cn {
  width: 0px;
  height: 0px;
}

.user-drp {
  color: $black__color;
  border-color: $blue__color;
  border-radius: 6px;
  border-width: 2px;
  background: $white__color;
}
.useralt {
  margin-right: 9px;
  height: 1.5rem;
}
.logout {
  height: 1rem;
  margin-right: 1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $dropdown__white__shade__color;
  min-width: 10.2em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a:hover {
  background-color: $white__color;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content a {
  color: $black__color;
  padding: 0.5em;
  text-decoration: none;
  display: block;
  background-color: $white__color;
}
#content-wrapper {
  text-align: center;
}
.rightmostelem {
  margin-top: -2em;
}

.inputWithIcon input[type="search"] {
  position: absolute;
  background-color: $white__shade__color;
  border-color: transparent;
  top: 0em;
  left: -18em;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 5rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}
.search-item {
  position: relative;
  color: $light__gray__color;
  position: relative;
  height: 2.3rem;
  left: -3.2rem;
  padding-top: 0.8rem;
}

//FOOTER//

footer {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background: $darkblue__color;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.mail {
  text-decoration: none;
}

// @media screen and (max-width: 1440px) {
//   #mobile {
//     display: block;
//     cursor: pointer;
//     padding: 2.4rem 0 0 27rem;
//     text-align: center;
//   }
// }

.logout-button{
  border-radius: 50% !important;
  border-color: #28ace2 !important;
  // background: url(../../../images/door-arrow-right.png) no-repeat;
  // background-color: #28ace2 !important;
  // color: #fff;

  background: url(../../../images/door-arrow-right.png) no-repeat;
  background-position: 0px -3px;
  background-repeat: no-repeat;
  background-size: 39px 43px;
  background-color: #28ace2 !important;
  color: #fff;
  height: 40px;
  width: 40px;
}
.logout-button.btn:hover{
  background-color: #00243D !important;
  border-color: #00243D !important;
}

// .logout-icon{
//   margin-top: -5px;
//   margin-left: -10px;
//   color: #fff;
// }
