@font-face {
  font-family: "MaerskHeadline-Bold";
  src: url("../fonts/MaerskHeadline-Bold.eot");
  src: url("../fonts/MaerskHeadline-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskHeadline-Bold.svg#MaerskHeadline-Bold") format("svg"),
    url("../fonts/MaerskHeadline-Bold.ttf") format("truetype"),
    url("../fonts/MaerskHeadline-Bold.woff2") format("woff2"),
    url("../fonts/MaerskHeadline-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskHeadline-Light";
  src: url("../fonts/MaerskHeadline-Light.eot");
  src: url("../fonts/MaerskHeadline-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskHeadline-Light.svg#MaerskHeadline-Light") format("svg"),
    url("../fonts/MaerskHeadline-Light.ttf") format("truetype"),
    url("../fonts/MaerskHeadline-Light.woff") format("woff"),
    url("../fonts/MaerskHeadline-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskHeadlineOffice-Bold";
  src: url("../fonts/MaerskHeadlineOffice-Bold.eot");
  src: url("../fonts/MaerskHeadlineOffice-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskHeadlineOffice-Bold.svg#MaerskHeadlineOffice-Bold")
      format("svg"),
    url("../fonts/MaerskHeadlineOffice-Bold.ttf") format("truetype"),
    url("../fonts/MaerskHeadlineOffice-Bold.woff") format("woff"),
    url("../fonts/MaerskHeadlineOffice-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskHeadlineOffice-Light";
  src: url("../fonts/MaerskHeadlineOffice-Light.eot");
  src: url("../fonts/MaerskHeadlineOffice-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskHeadlineOffice-Light.svg#MaerskHeadlineOffice-Light")
      format("svg"),
    url("../fonts/MaerskHeadlineOffice-Light.ttf") format("truetype"),
    url("../fonts/MaerskHeadlineOffice-Light.woff") format("woff"),
    url("../fonts/MaerskHeadlineOffice-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskHeadline-Regular";
  src: url("../fonts/MaerskHeadline-Regular.eot");
  src: url("../fonts/MaerskHeadline-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskHeadline-Regular.svg#MaerskHeadline-Regular")
      format("svg"),
    url("../fonts/MaerskHeadline-Regular.ttf") format("truetype"),
    url("../fonts/MaerskHeadline-Regular.woff") format("woff"),
    url("../fonts/MaerskHeadline-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskText-Bold";
  src: url("../fonts/MaerskText-Bold.eot");
  src: url("../fonts/MaerskText-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MaerskText-Bold.svg#MaerskText-Bold") format("svg"),
    url("../fonts/MaerskText-Bold.ttf") format("truetype"),
    url("../fonts/MaerskText-Bold.woff") format("woff"),
    url("../fonts/MaerskText-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskText-Light";
  src: url("../fonts/MaerskText-Light.eot");
  src: url("../fonts/MaerskText-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MaerskText-Light.svg#MaerskText-Light") format("svg"),
    url("../fonts/MaerskText-Light.ttf") format("truetype"),
    url("../fonts/MaerskText-Light.woff") format("woff"),
    url("../fonts/MaerskText-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskTextOffice-Bold";
  src: url("../fonts/MaerskTextOffice-Bold.eot");
  src: url("../fonts/MaerskTextOffice-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskTextOffice-Bold.svg#MaerskTextOffice-Bold")
      format("svg"),
    url("../fonts/MaerskTextOffice-Bold.ttf") format("truetype"),
    url("../fonts/MaerskTextOffice-Bold.woff") format("woff"),
    url("../fonts/MaerskTextOffice-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskTextOffice-Light";
  src: url("../fonts/MaerskTextOffice-Light.eot");
  src: url("../fonts/MaerskTextOffice-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskTextOffice-Light.svg#MaerskTextOffice-Light")
      format("svg"),
    url("../fonts/MaerskTextOffice-Light.ttf") format("truetype"),
    url("../fonts/MaerskTextOffice-Light.woff") format("woff"),
    url("../fonts/MaerskTextOffice-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskTextOffice-Regular";
  src: url("../fonts/MaerskTextOffice-Regular.eot");
  src: url("../fonts/MaerskTextOffice-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/MaerskTextOffice-Regular.woff2") format("woff2"),
    url("../fonts/MaerskTextOffice-Regular.woff") format("woff"),
    url("../fonts/MaerskTextOffice-Regular.ttf") format("truetype"),
    url("../fonts/MaerskTextOffice-Regular.svg#svgFontName") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MaerskText-Regular";
  src: url("../fonts/MaerskText-Regular.eot");
  src: url("../fonts/MaerskText-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MaerskText-Regular.svg#MaerskText-Regular") format("svg"),
    url("../fonts/MaerskText-Regular.ttf") format("truetype"),
    url("../fonts/MaerskText-Regular.woff") format("woff"),
    url("../fonts/MaerskText-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
