.icon {
  padding: "0px 18px";
}

.sidebar-menu {
  position: fixed;
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
  z-index: 1;
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-wrapper {
  margin-top: -71px;
  width: 100%;
  height: 100%;
  background: #f3f4f6;
  position: relative;
  // display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

// .sidebar-menu-header{
//   padding: 1rem !important;
// }
.sidebar-menu-collapse {
  background-color: #00243D;
}

.sidebar-menu-collapse.show {
  width: 250px !important;
}

.sidebar-menu-brand {
  background: #00243D;
  margin: 0 !important;
}

.sidebar-menu-dark .sidebar-menu-toggle {
  color: transparent // border-color: none
}

.sidebar-menu-nav-title {
  padding-left: 1.25rem;
  font-family: 'MaerskText-Regular';
  font-weight: 900;
}

.sidebar-menu-collapse.collapse {
  display: block;
}

.sidebar-menu-dark .sidebar-menu-toggle {
  border-color: transparent !important;
  position: relative;
  z-index: 9;
}

.sidebar-menu-sub-collapse {
  margin-left: 5px;
}

.sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-nav-link.active,
.sidebar-menu-dark .sidebar-menu-nav .active.sidebar-menu-sub-toggle {
  background-color: transparent;
}

.sidebar-menu-dark .sidebar-menu-sub-toggle {
  background-color: transparent !important;
}

.sidebar-menu-collapse:not(.show) {
  .sidebar-arrow-icon {
    background-image: none !important;
    display: none;
    color: #42B0D5;
  }

  .sidebar-menu-nav {
    color: #42B0D5;
  }
}

.sidebar-menu-collapse.show {
  .sidebar-arrow-icon {
    background-image: none !important;
    margin-left: 2.5rem;
    color: #42B0D5;
    position: absolute;
    right: 5px;
  }

  .sidebar-menu-nav {
    color: #42B0D5;
  }
}

.sidebar-menu-collapse:not(.show) .sidebar-menu-nav-title .sidebar-menu-sub-toggle {
  display: none;
}

.sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-nav-link:hover {
  color: #fff;
}

.sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-nav-link:active {
  color: #fff;
}

.sidebar-menu-dark .sidebar-menu-nav .sidebar-menu-nav-link {
  color: #42B0D5;
}