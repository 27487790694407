.grey__bg {
  background-color: $light__gray__color;
  overflow-x: hidden;
}

.container__width {
  max-width: 97%;
  padding-left: 2rem;
  margin-top: -2.5rem !important;
  .card {
    border-radius: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35%);
    border: none;
    border-radius: 2rem;
    margin-bottom: 0%;
  }
  p {
    font-family: $text__headline__regular;
    color: $black__color;
    font-size: 14px;
  }
  h1 {
    font-family: $text__headline__bold;
    color: $black__color;
    font-size: 20px;
    // padding: 1.8rem;
    margin-top: 2%;
  }

  a {
    text-decoration: none;
  }
}

.lab__heading {
  font-family: $text__headline__bold;
  color: $blue__color;
  margin-bottom: -5rem;
  font-size: 25px;
  padding: 2rem 0rem 1rem 5rem;
}
.row__padding {
  text-align: right;
  padding: 2rem;
}

.dataset__display {
  display: grid;
  grid-template-columns: auto auto;
  background-color: transparent;
  padding: 0px 28px;
  position: relative;
  img {
    width: 5rem;
  }
  h2 {
    font-family: $text__headline__bold;
  }
}
.menu__align {
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  padding: 2rem 0 0rem 1rem;
}
.monitor__padding {
  padding-top: 2rem;
}
.gutter__x {
  --bs-gutter-x: 4.5rem;
}

.global__text__header {
  display: flex;
  flex-direction: row;
  img {
    float: left;
  }
}

//RECENT ACTIVITIES

.recent__activities__main__header {
  font-size: 18px;
  font-family: $text__headline__bold;
  color: $blue__color;
}
// .modal-header .btn-close {
//   // padding: 2rem;
//   // margin-right: 19rem;
// }

.recent__sub__header {
  font-size: 18px;
  font-family: $text__headline__regular;
}
.recent__icon{
  background-color: #42B0D5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.recent__category {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  img {
    width: 3rem;
  }
  span {
    font-size: 14px;
    font-family: $text__headline__light;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    padding: 0rem 0 0rem 2rem;
  }
}
.recent__tool {
  font-style: italic;
}

.recent {
  padding-left: 0rem;
  padding-top: 2rem;
  padding-right: 2rem;
  .card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35%);
    border: none;
    border-radius: 2rem;
    width: 23rem;
  }
}
.recent__active {
  padding-top: 1rem;
  padding-left: 3rem;

  img {
    width: 4rem;
  }
  h2 {
    font-size: 25px;
    padding-top: 1rem;
    font-family: $text__regular;
  }
  h4,
  h5 {
    font-size: 16px;
    font-family: $text__regular;
  }
}

.modal.right .modal-dialog {
  position: absolute;
  margin: auto;
  width: 350px;
  height: 50%;
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right.fade .modal-dialog {
  top: 80px;
  right: 200px;
}
.loggedin__user__padding {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.hexa {
  position: absolute;
  background-color: rgba(66, 176, 213, 0.85);
  width: 13%;
  height: 90%;
  top: -45%;
  left: 37%;
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}
